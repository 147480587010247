const NoPage = () => {
    return (
        <>
        <h1>NoPage</h1>
        <h2 className="ops">OOPS! something went Wrong</h2>
        <p className="champs">Please Make Sure To Fill All info Correctly!</p>
        </>
        
    );
}
export default NoPage;